<template>
  <div>
    <van-nav-bar left-arrow left-text="返回" @click-left="goBack" title="查看详情-维修" />
    <!-- <input type="date"> -->
    <div style="padding:6px 16px 0px;background-color:#fff;margin-top:17px">
      <div class="tit">当天师傅报完工工单量: {{gdl}}</div>
      <div class="tit">当天师傅报完工设备台数：{{sbts}}</div>
      <!-- <div class="tit">{{date}}</div> -->

    </div>
    <van-cell title="选择日期" :value="date" @click="show = true" is-link />
    <van-calendar v-model="show" @confirm="onConfirm" :min-date="minDate" />
    <div style="margin-top:18px;background-color:#fff;padding-top:6px">
      <van-row style="padding:0 16px">
        <van-col span="2"><span class="listTitle">排名</span></van-col>
        <van-col span="12"><span class="listTitle">网点</span></van-col>
        <van-col span="5" @click="insFn"><span class="listTitle">工单量
            <van-icon :name="isIns?'arrow-down':'arrow-up'" />
          </span>
        </van-col>
        <van-col @click="repairFn" span="5"><span class="listTitle">设备台数
            <van-icon :name="isrepair?'arrow-down':'arrow-up'" />
          </span></van-col>
      </van-row>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了">
          <van-cell v-for="(item,i) in dataList" :key="i" @click="websitDetail(item.afterNumber)">
            <van-row>
              <van-col span="2">{{i+1}}</van-col>
              <van-col span="12">
                <div class="listTitle">{{item.name}}</div>
              </van-col>
              <van-col span="5">
                <div style="text-align:right">
                  <div class="fr">{{item.orderct}}</div>
                </div>
              </van-col>
              <van-col span="5">
                <div style="text-align:right">
                  <div class="listTitle">{{item.machinect}}</div>
                </div>
              </van-col>
            </van-row>
            <van-row>
              <van-col span="2"></van-col>
              <van-col span="12">
                <div class="listTitle">{{item.afterNumber}}</div>
              </van-col>
              <van-col span="5">
                <div style="text-align:right">
                  <div v-if="item.ztazgdl<0" class="downFloat"><img style="width:12px;height:12px" src="../../assets/icon/down.png" alt="">{{-item.ztazgdl }}%</div>
                  <div v-if="item.ztazgdl>0" class="upFloat"><img style="width:12px;height:12px" src="../../assets/icon/shangsheng.png" alt="">{{item.ztazgdl }}%</div>
                </div>
              </van-col>
              <van-col span="5">
                <div style="text-align:right">
                  <div v-if="item.ztsbs<0" class="downFloat"><img style="width:12px;height:12px" src="../../assets/icon/down.png" alt="">{{-item.ztsbs }}%</div>
                  <div v-if="item.ztsbs>0" class="upFloat"><img style="width:12px;height:12px" src="../../assets/icon/shangsheng.png" alt="">{{item.ztsbs }}%</div>
                </div>
              </van-col>
            </van-row>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { getUrlKey } from "@/utils/common";
export default {
  data() {
    return {
      show: false,
      minDate: new Date(2020, 1, 1),
      date: "",
      gdl: 0,
      sbts: 0,
      dataList: [],
      loading: false,
      finished: false,
      refreshing: false,
      isIns: false,
      isrepair: false,
    };
  },

  created() {
    this.date = this.$route.query.time.replace(/-/g, "/");
    this.getList();
  },
  methods: {
    //跳转网点详情
    websitDetail(value) {
      this.$router.push({
        path: "/masterFinished/repaireDetailWebsit",
        query: {
          website: value,
          time: this.date,
          startTime: this.date,
          endTime: this.date,
          type: "day",
        },
      });
    },
    onConfirm(date) {
      this.show = false;
      this.date = this.formatDate(date);
      this.getList();
    },
    //日期格式化
    formatDate(date) {
      return `${date.getFullYear()}/${
        date.getMonth() + 1 >= 10
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)
      }/${date.getDate() >= 10 ? date.getDate() : "0" + date.getDate()}`;
    },
    //维修筛选
    repairFn() {
      this.isrepair = !this.isrepair;
      if (this.isrepair) {
        this.dataList.sort((a, b) => {
          return b.machinect - a.machinect;
        }); /* 从大到小 */
      } else {
        this.dataList.sort((a, b) => {
          return a.machinect - b.machinect;
        }); /* 从小到大 */
      }
      // this.isrepair = false;
    },
    //安装筛选
    insFn() {
      this.isIns = !this.isIns;
      if (this.isIns) {
        this.dataList.sort((a, b) => {
          return b.orderct - a.orderct;
        }); /* 从大到小 */
      } else {
        this.dataList.sort((a, b) => {
          return a.orderct - b.orderct;
        }); /* 从小到大 */
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getList();
    },
    //获取列表数据
    getList() {
      let params = {
        openid: getUrlKey("openid", window.location.href),
        moduleId: "111111",
        type: "家用",
        startDate: this.date,
        endDate: this.date,
        timeConditionType: "day",
        sumType: 1,
        sumMode: "repair.comp",
      };
      this.postRequest("/wechat-analysis/summarys", params).then((res) => {
        let gdl = 0;
        let sbts = 0;
        res.data.forEach((v) => {
          gdl = gdl + v.orderct;
          sbts = sbts + v.machinect;
          let ztazgdl = 0;
          let ztsbs = 0;
          if (v.yorder && v.orderct) {
            ztazgdl =
              (((v.orderct - v.yorder) / v.yorder) * 100).toFixed(2) * 1;
          }
          if (v.machinect && v.ymachine) {
            ztsbs =
              (((v.machinect - v.ymachine) / v.ymachine) * 100).toFixed(2) * 1;
          }
          v.ztazgdl = ztazgdl;
          v.ztsbs = ztsbs;
        });
        this.gdl = gdl;
        this.sbts = sbts;
        if (this.refreshing) {
          this.dataList = [];
          this.refreshing = false;
        }
        if (res.data.length === 0) {
          this.dataList = [];
          this.finished = true;
        }
        this.dataList = res.data;
        this.isIns = false;
        this.insFn();
        this.loading = false;
        if (this.dataList.length >= res.data.length) {
          this.finished = true;
        }
      });
    },

    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.tit {
  font-weight: 700;
  font-size: 14px;
}
.listTitle {
  font-weight: 700;
  font-size: 12px;
}
</style>
